import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useState } from 'react';

const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  background-color: #B7BEAE;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
`;

const LogoContainer = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #000;
`;

const LogoImage = styled.img`
  height: 40px; // Adjust this value as needed
  width: auto;
  margin-right: 0.5rem;
`;

const LogoText = styled.span`
  font-size: 1.2rem;
  font-weight: bold;
  
  @media (max-width: 768px) {
    display: none; // Hide text on mobile if needed
  }
`;

const MenuIcon = styled.div`
  display: block;
  cursor: pointer;
  
  @media (min-width: 768px) {
    display: none;
  }
`;

const NavItems = styled.ul`
  display: ${props => props.isOpen ? 'flex' : 'none'};
  flex-direction: column;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: #B7BEAE;
  padding: 1rem;
  
  @media (min-width: 768px) {
    display: flex;
    flex-direction: row;
    position: static;
    padding: 0;
  }
`;

const NavItem = styled.li`
  list-style: none;
  margin: 0.5rem 0;
  
  @media (min-width: 768px) {
    margin: 0 0 0 1rem;
  }
`;

const NavLink = styled(Link)`
  text-decoration: none;
  color: #000;
  font-size: 1.25rem;
`;

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <Nav>
      <LogoContainer to="/">
        <LogoImage src="/MN_Logo.png" alt="MN Logo" />
        <LogoText>  </LogoText>
      </LogoContainer>
      <MenuIcon onClick={toggleMenu}>☰</MenuIcon>
      <NavItems isOpen={isMenuOpen}>
        <NavItem><NavLink to="/">Home</NavLink></NavItem>
        <NavItem><NavLink to="/about">About</NavLink></NavItem>
        <NavItem><NavLink to="/services">Services</NavLink></NavItem>
        <NavItem><NavLink to="/contact">Contact</NavLink></NavItem>
      </NavItems>
    </Nav>
  );
};

export default Navbar;
