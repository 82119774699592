import ContactForm from '../components/ContactForm';

const Contact = () => {
  return (
    <section style={{ 
      padding: '2rem', 
      textAlign: 'center',
      maxWidth: '800px',  // Limit the width
      margin: '0 auto',   // Center the content
      paddingTop: '80px'  // Add top padding to account for navbar
    }}>
      <h1>Contact Us</h1>
      <p>We'd love to hear from you. Please fill out the form below to get in touch with us.</p>
      <p>Include a detailed message with what you are hoping to achieve along with a good phone number to contact you.</p>
      <p>We will get back to you as soon as possible.</p>
      <ContactForm />
    </section>
  );
};

export default Contact;
