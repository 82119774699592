import React from 'react';
import styled from 'styled-components';
import HeroSection from '../components/HeroSection';
import ServiceCard from '../components/ServiceCard';
import ProjectGallery from '../components/ProjectGallery';
//import heroBg from '../assets/hero1-bg.png'; // Adjust the path to your image

// Create a styled container for the background with better control over image size
const HomeContainer = styled.div`
 
  background-size: contain; /* Or 'auto', 'cover', or specify a percentage or pixels */
  background-position: top center; /* Adjust positioning as needed */
  background-repeat: no-repeat;
  min-height: 100vh; /* You can reduce height if the background is too large */
  width: 100%;
`;
//  background-image: url(${heroBg});

const ServicesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
  padding: 1rem;
`;

const Home = () => {
  return (
    <HomeContainer>
      <HeroSection />
      <section style={{ padding: '2rem', textAlign: 'center' }}>
        <h2>Our Services</h2>
        <ServicesContainer>
          <ServiceCard title="Interior Design" description="We enhance your home's look with expert interior design, including cabinets, tiles, and paint to match your style." />
          <ServiceCard title="Renovations" description="Our remodeling services transform your kitchen, bathroom, or any space with new flooring, layouts, and extensions." />
          <ServiceCard title="Repairs" description="We handle all home repairs, ensuring quick and reliable fixes to keep your house in top condition." />
        </ServicesContainer>
      </section>
      <section style={{ padding: '2rem', textAlign: 'center' }}>
        <h2>Featured Projects</h2>
        <ProjectGallery />
      </section>
    </HomeContainer>
  );
};

export default Home;
