import ServiceCard from '../components/ServiceCard';

const Services = () => {
  return (
    <section style={{ padding: '4rem 2rem 2rem', textAlign: 'center' }}>
      <h1>Our Services</h1>
      <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
        <ServiceCard title="Interior Design" description="We help transform your home’s interior with beautiful cabinets, tiles, and paint. We work with you to choose the right materials and finishes to match your style. From small updates to full room makeovers, we bring your vision to life." />
        <ServiceCard title="Renovations" description="We offer complete remodeling services for kitchens, bathrooms, and more. Whether it’s new flooring, a fresh layout, or an extension, we handle every step of the process. Our goal is to improve your space while keeping it functional and stylish." />
        <ServiceCard title="Repairs" description="We take care of all your home repairs, from fixing leaks to patching walls. Our skilled team ensures everything is repaired quickly and correctly. No job is too small to keep your home running smoothly." />
      </div>
    </section>
  );
};

export default Services;
