import styled from 'styled-components';
import { motion } from 'framer-motion';
import heroBg from '../assets/hero1-bg.png';

const HeroContainer = styled.section`
  min-height: 100vh;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;
  gap: 1rem;
  padding: 1rem;
  padding-top: 60px; // Add this line to account for the fixed navbar
  color: black;
  font-family: 'Playfair Display', serif;
  overflow: hidden;

  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto 1fr;
  }

  @media (min-width: 1024px) {
    grid-template-columns: 1fr 2fr 1fr;
  }
`;

const TitleContainer = styled.div`
  grid-column: 1 / -1;
  text-align: center;

  @media (min-width: 768px) {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    text-align: left;
    align-self: start;
  }

  @media (min-width: 1024px) {
    grid-column: 1 / 2;
  }
`;

const Title = styled(motion.h1)`
  font-size: clamp(2rem, 5vw, 3.5rem);
  font-weight: bold;
  margin: 0;
`;

const Subtitle = styled(motion.span)`
  font-size: clamp(1rem, 2vw, 1.2rem);
  margin-top: 0.5rem;
  display: block;
  font-weight: normal;
`;

const Collage = styled(motion.div)`
  width: 100%;
  height: 50vh;
  background-image: url(${heroBg});
  background-size: cover;
  background-position: center;
  grid-column: 1 / -1;

  @media (min-width: 768px) {
    grid-column: 1 / -1;
    grid-row: 2 / 3;
    height: 60vh;
  }

  @media (min-width: 1024px) {
    grid-column: 2 / 3;
    grid-row: 1 / 3;
    height: 80vh;
  }
`;

const Slogan = styled(motion.p)`
  font-family: 'Montserrat', sans-serif;
  font-size: clamp(1rem, 3vw, 1.5rem);
  font-weight: normal;
  text-align: center;
  line-height: 1.5;
  grid-column: 1 / -1;

  @media (min-width: 768px) {
    grid-column: 2 / 3;
    grid-row: 3 / 3;
    text-align: right;
    align-self: end;
  }

  @media (min-width: 1024px) {
    grid-column: 3 / 4;
    grid-row: 1 / 2;
  }
`;

const HeroSection = () => {
  return (
    <HeroContainer>
      {/* Title and Subtitle aligned to the top-left */}
      <TitleContainer>
        <Title
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
        >
          MN CONSTRUCTION
        </Title>
        <Subtitle
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, delay: 0.3 }}
        >
          EST 2016
        </Subtitle>
      </TitleContainer>

      {/* Collage positioned left of the slogan */}
      <Collage
        initial={{ opacity: 0, scale: 0.8 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 1.5 }}
      />

      {/* Slogan in the middle right of the page */}
      <Slogan
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1, delay: 0.5 }}
      >
        BUILDING DREAMS, <br />MAINTAINING<br /> EXCELLENCE
      </Slogan>
    </HeroContainer>
  );
};

export default HeroSection;
