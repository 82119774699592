import React from 'react';
import styled from 'styled-components';
import project1 from '../assets/project1.jpg';
import project2 from '../assets/project2.jpg';
import project3 from '../assets/project3.jpg';

const GalleryContainer = styled.section`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 2rem;
`;

const ProjectImage = styled.img`
  width: 30%;
  margin-bottom: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const ProjectGallery = () => {
  const handleImageError = (e) => {
    console.error(`Failed to load image: ${e.target.src}`);
    e.target.src = 'https://via.placeholder.com/300x200?text=Image+Not+Found';
  };

  return (
    <GalleryContainer>
      <ProjectImage 
        src={project1} 
        alt="Project 1" 
        onError={handleImageError}
      />
      <ProjectImage 
        src={project2} 
        alt="Project 2" 
        onError={handleImageError}
      />
      <ProjectImage 
        src={project3} 
        alt="Project 3" 
        onError={handleImageError}
      />
    </GalleryContainer>
  );
};

export default ProjectGallery;
